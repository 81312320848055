import {
  getOrCreateOnboarding,
  completeStep as completeStepApi,
  skipOnboarding as skipOnboardingApi,
} from '@wix/ambassador-groups-onboarding-v1-onboarding/http';

import { createAsyncThunk } from '../utils';

// selectors
import { selectOnboarding, selectGroup } from 'store/selectors';

// types
import { Role as GroupRole } from '@wix/ambassador-social-groups-v2-group-role/types';

import { actions } from './index';

import {
  type CompleteStepRequest,
  OnboardingStatus,
} from '@wix/ambassador-groups-onboarding-v1-onboarding/types';

export const initialize = createAsyncThunk(
  'onboarding:initialize',
  async function (groupId: string, thunkAPI) {
    const state = thunkAPI.getState();

    const { data, meta } = selectOnboarding(state);
    const group = selectGroup(state, groupId);

    const isAvailableRole = [GroupRole.ADMIN, GroupRole.MEMBER].includes(
      group.role,
    );
    const isInitialization = data.status === OnboardingStatus.INITIALIZING;

    const shouldFetchData = (isAvailableRole && isInitialization) || meta.error;
    if (shouldFetchData) {
      return thunkAPI.extra.httpClient
        .request(getOrCreateOnboarding({ groupId }))
        .then((response) => response.data.onboarding);
    } else {
      return thunkAPI.fulfillWithValue(data);
    }
  },
);

export const completeStep = createAsyncThunk(
  'onboarding:completeStep',
  async function (params: CompleteStepRequest, thunkAPI) {
    return thunkAPI.extra.httpClient
      .request(completeStepApi(params))
      .then((response) => ({ data: response.data.onboarding, params }));
  },
);

export const skipOnboarding = createAsyncThunk(
  'onboarding:skipOnboarding',
  async function (groupId: string, thunkAPI) {
    const state = thunkAPI.getState();
    const {
      data: { steps },
    } = selectOnboarding(state);
    const isAllStepsCompleted = steps.every(
      (step) => step.status === 'COMPLETED',
    );
    if (isAllStepsCompleted) {
      return thunkAPI.dispatch(actions.closeOnboarding());
    } else {
      return thunkAPI.extra.httpClient
        .request(skipOnboardingApi({ onboardingId: groupId }))
        .then((response) => response.data.onboarding);
    }
  },
);
